interface VideoReview {
  url: string;
  title: string;
  description: string;
  name: string;
}

export const videoReviews: VideoReview[] = [
  {
    url: 'https://res.cloudinary.com/drfweekiv/video/upload/v1721977320/wb_first_vidmp4.mp4',
    title: 'Gorgeous keepsake',
    description: `"I bought one as soon as I found out I was pregnant. This book has truly helped me create a detailed and beautiful record of my baby's growth."`,
    name: 'Jessica S.',
  },
  {
    url: 'https://res.cloudinary.com/drfweekiv/video/upload/v1721977320/222222.mp4',
    title: 'We love games from the book',
    description: `"It's full of spaces for memories and games for development. Lucas is now 10 months old, and playing together is our favorite part of the day!"`,
    name: 'Jolene J.',
  },
  {
    url: 'https://res.cloudinary.com/drfweekiv/video/upload/v1721907688/wb-1.mp4',
    title: 'Exceeded my expectations!',
    description: `"I am so pleased with my son’s book! The details and the making of this book went high above my expectations. I highly recommend this for new parents."`,
    name: 'Zoey W.',
  },
  {
    url: 'https://res.cloudinary.com/drfweekiv/video/upload/v1721907647/wb-4.mp4',
    title: 'Can’t wait to show it to my son',
    description: `"Journaling my pregnancy journey helped me relax. Now that my boy is 2 months old, I capture every milestone & can’t wait to show it to him when he grows up!"`,
    name: 'Taylor',
  },
  {
    url: 'https://res.cloudinary.com/drfweekiv/video/upload/v1721978154/pataisytas.mp4',
    title: 'Great as a gift, 10/10!',
    description: `"It has so many prompts to document our baby’s first words, steps, and much more! The activities in the book help us reach these milestones on time. 10/10!"`,
    name: 'Emilia L.',
  },
];

interface UserReview {
  author: string;
  comment: string;
}

export const userReviews: UserReview[] = [
  {
    author: 'Aubrey',
    comment:
      'This book is more personalized than I expected! It’s the prettiest book that I have at home.',
  },
  {
    author: 'Gabriela',
    comment:
      'I’m pretty sure it’s the only baby book out there that could be customized for single moms.',
  },
  {
    author: 'Martha',
    comment: `Very happy with this memory book. Definitely going to get one for all my babies in the future! 😇`,
  },
  {
    author: 'Amanda T.',
    comment:
      'Tears were flowing once I opened the package. Well worth every penny.',
  },
  {
    author: 'Cassandra L.',
    comment:
      'My best friend is obsessed with her gift! Will definitely get one for myself when I am pregnant.',
  },
  {
    author: 'Karen D.',
    comment:
      'It looks amazing. If you’re looking for a good baby book this is the one.',
  },
];

interface SuccessStory {
  imgUrl: string;
  story: string;
  author: string;
}

export const SuccessStories: SuccessStory[] = [
  {
    imgUrl:
      'https://res.cloudinary.com/drfweekiv/image/upload/v1721908654/Frame_1214134128_6.png',
    story: `"I am so happy I found this book! Not only is it great for capturing our special moments, but also for creating new ones. The book has plenty of games & activities for bonding and learning, and we love it! It’s the best book out there!"`,
    author: 'Emily R., 30',
  },
  {
    imgUrl:
      'https://res.cloudinary.com/drfweekiv/image/upload/v1721908654/Frame_1214134128_7.png',
    story:
      '"My sister in law gave us this book when Isabella was born. I can’t stop smiling looking at these pages, all her first milestones are here. I love this book so much, I already gave it to two of my friends as their baby shower gifts."',
    author: 'Amanda T., 32',
  },
  {
    imgUrl:
      'https://res.cloudinary.com/drfweekiv/image/upload/v1721908655/2_586.png',
    story:
      '"Welcome Baby is such a sentimental baby book...I actually got so emotional while writing a letter to my baby girl to read in the future. I imagine us sitting down together in 20 years and looking back at these memories with tears in our eyes."',
    author: 'Patricia L., 26',
  },
  {
    imgUrl:
      'https://res.cloudinary.com/drfweekiv/image/upload/v1721908655/Frame_1214134128_8.png',
    story:
      '"I was eyeing Welcome Baby for months before my pregnancy test turned out positive. Now that we got it made just for our family, it looks even prettier in real life than I expected. And the quality is fantastic."',
    author: 'Elizabeth, 29',
  },
  {
    imgUrl:
      'https://res.cloudinary.com/drfweekiv/image/upload/v1721908655/Frame_1214134128_9.png',
    story:
      '"This is a great baby book. It’s so pretty and personalized. The journaling pages are so helpful too! I love to capture all his firsts to forever remember. My husband and I are so happy looking at these pages and I show it off every time my friends come."',
    author: 'Anna L., 24',
  },
  {
    imgUrl:
      'https://res.cloudinary.com/drfweekiv/image/upload/v1721908655/Frame_1214134128_10.png',
    story:
      '"This is my favorite gift I received at baby shower. I love how much direction it gives for documenting our milestones without restricting my creative freedom. I am expecting my second child and I already got a book for her as well."',
    author: 'P. Sarah, 27',
  },
];
